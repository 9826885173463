export default {
  state: {
    alerts: [],
    alertCount: 0,
    showFactoringDiv: false
  },
  getters: {
    getAlerts: (state) => state.alerts,
    getShowFactoringDiv: (state) => state.showFactoringDiv
  },
  mutations: {
    //newAlert:{msgType, msg}
    addAlert(state, { msgType, msg }) {
      state.alerts.push({ id: state.alertCount++, msg, msgType })
    },
    removeAlert(state, key) {
      state.alerts = state.alerts.filter((alert) => alert.id !== key)
    },
    displayFactoringDiv(state, value) {
      state.showFactoringDiv = value
    }
  }
  // actions: {},
}

const siteName = 'sleepertrader',
  terms = {
    cookiePolicy: {
      text: 'Cookie Policy',
      url:
        'https://app.termly.io/document/cookie-policy/5c5bfe47-d785-44e2-91ce-87687fafc232',
      endingText: ' ,'
    },
    termOfUse: {
      text: 'Terms of Use',
      url:
        'https://app.termly.io/document/terms-of-use-for-website/f7030bfe-e2b2-41cd-9496-43c091bf74d2',
      endingText: ' and'
    },
    privacyPolicy: {
      text: 'Privacy Policy',
      url:
        'https://app.termly.io/document/privacy-policy/ec999db2-886f-497d-b6e1-e5eefb70c412',
      endingText: '.'
    }
  },
  GA_TRACKING_ID = 'UA-111413542-1'

export { siteName, terms, GA_TRACKING_ID }

import axios from 'axios'
// {
//   method(required), headers, data(for post)
// }
export default (options, siteName, needExtraHeader = true) => {
  if (needExtraHeader) {
    if (options.headers) {
      options.headers.site = 'truck'
      options.headers['x-site-name'] = siteName
    } else options.headers = { site: 'truck', 'x-site-name': siteName }
  }
  return axios(options)
}

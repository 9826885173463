import { loanPayment } from '../sharedConfigs/miscConfig'
export default (defaultOffer, truckType, extraInfoForDealerTruck) => {
  if (
    truckType === 'dealer' &&
    (extraInfoForDealerTruck.dealerId === '5c4f5afd04158a0004dc727b' ||
      new Date().getFullYear() - extraInfoForDealerTruck.yearOfTruck >= 6)
  )
    return 'Low monthly payments'
  if (!defaultOffer) {
    if (truckType === 'lessor') return 'Payments TBD'
    if (truckType === 'dealer') {
      if (extraInfoForDealerTruck.listPrice) {
        let // loanPayment = {
          //   down: 0.2,
          //   interest: 0.1999,
          //   term: 48,
          // },
          principal =
            extraInfoForDealerTruck.listPrice -
            extraInfoForDealerTruck.listPrice * loanPayment.down,
          rate = loanPayment.interest / 12
        return `$${parseInt(
          (principal * rate) / (1 - Math.pow(1 + rate, -loanPayment.term))
        ).toLocaleString()}/mo for 48 months*`
      }
    }
  } else if (defaultOffer.weeklyPayment) {
    return `$${parseInt(defaultOffer.weeklyPayment).toLocaleString()}/wk for ${
      defaultOffer.maxTermLength
    } weeks`
  } else if (defaultOffer.monthlyPayment) {
    return `$${parseInt(defaultOffer.monthlyPayment).toLocaleString()}/mo for ${
      defaultOffer.maxTermLength
    } months`
  }
}

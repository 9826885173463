const API_CACHE = {
    url: 'https://b8rh6wl2f3.execute-api.us-east-1.amazonaws.com/live',
    // url: 'http://localhost:3000',
    endpoints: {
      getTrucks: {
        endpoint: '/fetchPoolTrucks',
        responseKeyName: 'data'
      }
    }
  },
  API_NUCLEUS = {
    url: 'https://nucleus-service.herokuapp.com',
    // url: 'http://localhost:8080',
    endpoints: {
      validateProfile: {
        endpoint: '/validateProfile',
        responseKeyName: 'errors'
      },
      sendTruckLead: {
        endpoint: '/sendTruckLead'
      },
      sendPartnerAdLead: {
        endpoint: '/partner-ad-respondents'
      }
    }
  },
  API_S3 = {
    url: 'https://d2oykiyxwcuabs.cloudfront.net',
    endpoints: { partnerAdImage: '/partner-ads' }
  },
  API_TRUCK = {
    url: 'https://access-truck-service.herokuapp.com',
    // url: 'http://localhost:8080',
    endpoints: {
      getTruck: { endpoint: '/getTruck', responseKeyName: 'truck' },
      getTrucks: { endpoint: '/getTrucks', responseKeyName: 'data' },
      getDistinctFields: {
        endpoint: '/getDistinctFields',
        responseKeyName: 'Types',
        requestBodyKeyName: 'fieldType'
      },
      getClients: { endpoint: '/getClients', responseKeyName: 'clients' }
    }
  }
export { API_CACHE, API_S3, API_TRUCK, API_NUCLEUS }

<template>
  <div class="img-block">
    <img :src="imgSrc" :alt="imgConfig.alt || 'placeholder'" />
    <div class="more-info" v-if="showTextHint">Click for more</div>
  </div>
</template>

<script>
  import { API_S3 } from '../sharedConfigs/apiConfig'
  import { isNonEmptyString } from '../sharedHelpers/stringUtil'
  import { placeHolderImg } from '../sharedConfigs/miscConfig'
  export default {
    name: 'ImageBlock',
    props: {
      //clientID, picName, unitID, alt
      imgConfig: {
        type: Object,
        default() {
          return {}
        }
      },
      showTextHint: {
        type: Boolean,
        default: true
      }
    },
    data: () => {
      return {
        imgSrc: null
      }
    },
    created() {
      let { clientID, picName, unitID } = this.imgConfig
      let canCreateImgUrl =
        isNonEmptyString(clientID) &&
        isNonEmptyString(picName) &&
        isNonEmptyString(unitID)
      this.imgSrc = `${API_S3.url}/${
        canCreateImgUrl
          ? `${clientID}/${unitID}/${
              this.showTextHint ? '320x240' : '640x480'
            }/${picName}`
          : placeHolderImg
      }`
      // this.imgSrc = `${API_S3.url}/${
      //   canCreateImgUrl
      //     ? `${clientID}${this.showTextHint ? '' : '/raw'}/${picName}`
      //     : placeHolderImg
      // }`
    }
  }
</script>

<style lang="scss">
  .img-block {
    display: inline-block;
    scroll-snap-align: start;
    width: 100%;
    padding-top: 75%;
    // border-radius: 0.5rem;
    position: relative;
    img {
      color: $gray-5;
      position: absolute;
      top: 0;
      border-radius: 0.5rem;
      width: 100%;
    }
    span {
      @include font(14px);
      color: $gray-5;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .more-info {
    @include font(12px);
    color: $white;
    @include borderAndBackgroundColor(
      $transparent-black,
      0.5px solid $transparent-black,
      0.2rem
    );
    padding: 2px 4px;
    @include blockPosition(absolute, 1px, 1px);
    z-index: 1;
  }
  @media screen and (max-width: 420px) {
    .img-block {
      span {
        font-size: 11px;
      }
    }
  }
</style>

import httpRequest from '../sharedHelpers/httpRequest'
import { API_NUCLEUS } from '../sharedConfigs/apiConfig'
export default (leadInfo, siteName) =>
  httpRequest(
    {
      url: `${API_NUCLEUS.url}${API_NUCLEUS.endpoints.sendTruckLead.endpoint}`,
      method: 'post',
      'x-request-id': leadInfo.buyer.sessionId,
      data: leadInfo
    },
    siteName
  )
// {
//   console.log(leadInfo)
//   return {
//     data: {
//       status: true,
//       buyer: {
//         name: { firstname: 'Longfei', lastname: 'Ma' },
//         phone: '347-379-3680'
//       }
//     }
//   }
//   return new Promise((resolve, reject) => {
//     setTimeout(function () {
//       resolve({
//         data: {
//           status: true,
//           buyer: {
//             name: { firstname: 'Longfei', lastname: 'Ma' },
//             phone: '347-379-3680'
//           }
//         }
//       })
//     }, 1500)
//   })
// }

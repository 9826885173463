import partnerAdLeadRequest from './partnerAdLeadRequest'
import { adsByPartners } from '../sharedConfigs/partnerAdsConfig'

//assume same leadType from different partner will have the same format
//if this is not true the function need to be re-evaluated
const partnerAdLead = async (
  targetVueComponent,
  partnerAdRespondentProfile,
  siteName
) => {
  let { leadTypes, firstName, lastName, email, phoneNumber, company } =
      partnerAdRespondentProfile,
    partnerAdRespondent = {
      firstName,
      lastName,
      email,
      phoneNumber
    },
    partnerAdInfo = {
      marketingSource: targetVueComponent.$route.query.m || 'misc'
    }
  if (company) partnerAdRespondent.company = company
  //if from checkBox, no partner provided, no success or error msg
  if (!partnerAdRespondentProfile.partner) {
    let leadTypesByPartners = {}
    for (let i = 0; i < leadTypes.length; i++) {
      let leadType = leadTypes[i]
      for (let partner in adsByPartners) {
        if (adsByPartners[partner][leadType]) {
          if (!leadTypesByPartners[partner])
            leadTypesByPartners[partner] = { [leadType]: true }
          else leadTypesByPartners[partner][leadType] = true
        }
      }
    }
    partnerAdInfo.adFormat = 'checkBox'
    for (let partnerName in leadTypesByPartners) {
      try {
        let leadTypesForPartner = leadTypesByPartners[partnerName]
        partnerAdInfo.partner = partnerName
        partnerAdInfo.leadTypes = Object.keys(leadTypesForPartner)
        await partnerAdLeadRequest(
          {
            partnerAdRespondent,
            partnerAdInfo
          },
          siteName
        )
        for (let i = 0; i < leadTypes.length; ) {
          if (leadTypesForPartner[leadTypes[i]])
            targetVueComponent.$store.commit('updatePartnerAdProfile', {
              adType: leadTypes.splice(i, 1)[0],
              profile: partnerAdRespondent
            })
          else i++
        }
      } catch (error) {
        // console.log(error.response.body, error.response.status)
        continue
      }
    }
  } else {
    partnerAdInfo.adFormat = 'display'
    partnerAdInfo.leadTypes = leadTypes
    partnerAdInfo.partner = partnerAdRespondentProfile.partner
    if (partnerAdRespondentProfile.message)
      partnerAdInfo.message = partnerAdRespondentProfile.message
    if (partnerAdRespondentProfile.mc_dot_number)
      partnerAdRespondent.mc_dot_number =
        partnerAdRespondentProfile.mc_dot_number
    try {
      await partnerAdLeadRequest(
        { partnerAdRespondent, partnerAdInfo },
        siteName
      )
      targetVueComponent.$store.commit('addAlert', {
        msg: 'Message sent to agent!',
        msgType: 'success'
      })
      for (let i = 0; i < leadTypes.length; i++)
        targetVueComponent.$store.commit('updatePartnerAdProfile', {
          adType: leadTypes[i],
          profile: partnerAdRespondent
        })
      if (targetVueComponent.popupSwitch)
        targetVueComponent.popupSwitch.showAdEleadPopup = false
    } catch (error) {
      if (error.response.status > 499) {
        targetVueComponent.$store.commit('addAlert', {
          msg: 'Message Failed, please try again shortly!',
          msgType: 'error'
        })
      }
    }
  }
}

export { partnerAdLead }

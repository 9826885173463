const horsepowerFilters = {
    '0-449': 'Below 449hp',
    '450-499': '450hp - 499hp',
    '500-549': '500hp - 549hp',
    '550-599': '550hp - 599hp',
    '600-1000': 'Above 600hp'
  },
  hasImageFilters = {
    true: 'Units With Images',
    undefined: 'All Units'
  },
  priceFilters = {
    '0-24999': '$0 - $24,999',
    '25000-39999': '$25,000 - $39,999',
    '40000-59999': '$40,000 - $59,999',
    '60000-79999': '$60,000 - $79,999',
    '80000-999999': 'Over $80,000'
  },
  stateFilters = {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming'
  },
  creditScoreRange = {
    800: '800 or Higher',
    799: '750 to 799',
    749: '700 to 749',
    699: '650 to 699',
    649: '600 to 649',
    599: '550 to 599',
    549: '500 to 549'
  }
// ,
// categoryFiltersAlts = {
//   'Conventional W/ Sleeper': 'Sleeper Truck',
//   'Conventional W/o Sleeper': 'Day Cab Truck',
//   'Glider Kit Trucks': 'Glider Truck',
//   'Dry Van Trailers': 'Dry Van Trailer',
//   'Flatbed Trailers': 'Flatbed Trailer',
//   'Reefer Trailers': 'Reefer Trailer',
// }

export {
  stateFilters,
  priceFilters,
  horsepowerFilters,
  hasImageFilters,
  creditScoreRange
  // categoryFiltersAlts,
}

import {
  truckTypesAndRoutesMap,
  truckDescriptionBySite
} from '../sharedConfigs/miscConfig'
import { categoryFilters } from '../access-trucks/src/config/filterConfig'

//aggregate page and individual page both use plural for now
//alt of image on aggregate page use singular
const getUnitName = (category, manufacturer, siteName, isPlural = true) => {
    let unitType = truckDescriptionBySite[siteName].truckDescription
    if (siteName !== 'daycabtrader') {
      let found = false
      if (category !== undefined) {
        let categoriesMap = {
          sleepertrader: {
            'Dry Van Trailers': 'Semi Trailers',
            'Flatbed Trailers': 'Semi Trailers',
            'Reefer Trailers': 'Semi Trailers',
            'Conventional W/ Sleeper': 'Semi Trucks',
            'Glider Kit Trucks': 'Glider Kit Trucks'
          },
          accesstrucks: {
            ...categoryFilters,
            //to align return statement
            ...(isPlural
              ? {
                  Other: 'Other Trucks And Trailers',
                  'Conventional W/o Sleeper': 'Conventional W/o Sleeper',
                  'Cab & Chassis': 'Cab & Chassis'
                }
              : {
                  Other: 'Other Truck And Trailers',
                  'Conventional W/o Sleeper': 'Conventional W/o Sleepers',
                  'Cab & Chassis': 'Cab & Chassiss'
                })
          }
        }
        if (categoriesMap[siteName][category]) {
          found = true
          unitType = categoriesMap[siteName][category]
        }
      }
      if (manufacturer !== undefined && !found) {
        let manufacturersMap = {
          sleepertrader: {
            Utility: 'Trailers',
            'Great Dane': 'Trailers',
            Wabash: 'Trailers',
            Other: 'Trailers'
          },
          accesstrucks: {
            Benson: 'Trailers',
            Dorsey: 'Trailers',
            'Eager Beaver': 'Trailers',
            East: 'Trailers',
            Etnyre: 'Trailers',
            Fontaine: 'Trailers',
            Fruehauf: 'Trailers',
            'Great Dane': 'Trailers',
            Mac: 'Trailers',
            Manac: 'Trailers',
            Other: 'Trucks & Trailers',
            Polar: 'Trailers',
            Ravens: 'Trailers',
            Reitnouer: 'Trailers',
            Rogers: 'Trailers',
            Stoughton: 'Trailers',
            Strick: 'Trailers',
            Talbert: 'Trailers',
            Timpte: 'Trailers',
            'Trail King': 'Trailers',
            Trailmobile: 'Trailers',
            Transcraft: 'Trailers',
            Travis: 'Trailers',
            Utility: 'Trailers',
            Vanguard: 'Trailers',
            Wabash: 'Trailers',
            Wilkens: 'Trailers',
            Wilson: 'Trailers',
            'Witzco Challenger': 'Trailers',
            'XL Specialized': 'Trailers'
          }
        }
        if (manufacturersMap[siteName][manufacturer]) {
          let unitTypeArr = unitType.split(' ')
          unitTypeArr[unitTypeArr.length - 1] =
            manufacturersMap[siteName][manufacturer]
          unitType = unitTypeArr.join(' ')
        }
      }
    }
    // return unitType
    return isPlural ? unitType : unitType.slice(0, -1)
  },
  getAggregationPageTitleAndDescription = (
    siteName,
    truckType,
    trucksAttributes,
    dealerName
  ) => {
    let { year, model, manufacturer, state, category } = trucksAttributes,
      unitName = getUnitName(category, manufacturer, siteName),
      salesType = truckTypesAndRoutesMap[truckType].salesType,
      title = `${year ? year + ' ' : ''}${
        manufacturer ? manufacturer + ' ' : ''
      }${model ? model + ' ' : ''}${unitName} ${salesType}${
        state ? ' In ' + state : ''
      }${dealerName ? ` from ${dealerName}` : ''}`,
      siteNameTitleCase = truckDescriptionBySite[siteName].siteNameTitleCase
    return {
      title: `${title} | ${siteNameTitleCase}`,
      description: `Find New and Used ${title} at ${siteNameTitleCase}.com. Your Trusted Source since 2008 for ${unitName} ${salesType} by ${
        manufacturer
          ? manufacturer
          : /trailer/i.test(unitName)
          ? 'Utility, Great Dane, Wabash'
          : 'Peterbilt, Kenworth, Volvo, International, Freightliner, Western Star'
      } and Many More!`
    }
  },
  getIndividualPageTitleAndDescription = (
    siteName,
    truckType,
    trucksAttributes
  ) => {
    let { year, model, manufacturer, _id, category } = trucksAttributes,
      title = `${year} ${manufacturer}${model ? ' ' + model : ''}`,
      siteNameTitleCase = truckDescriptionBySite[siteName].siteNameTitleCase
    return {
      title: `Used ${title} | ${siteNameTitleCase} - ${_id}`,
      description: `Find Used ${title} ${getUnitName(
        category,
        manufacturer,
        siteName
      )} ${
        truckTypesAndRoutesMap[truckType].salesType
      } at ${siteNameTitleCase}.com.`
    }
  }

export {
  getUnitName,
  getAggregationPageTitleAndDescription,
  getIndividualPageTitleAndDescription
}

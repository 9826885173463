<template>
  <div class="page-footer">
    <div class="page-footer-inner">
      <PageNavBar />
    </div>
  </div>
</template>

<script>
  import PageNavBar from '@/components/PageNavBar'
  export default {
    name: 'PageFooter',
    components: { PageNavBar },
  }
</script>

<style lang="scss">
  .page-footer {
    @extend .banner-basic;
    height: 48px;
    background-color: $white;
    bottom: 0;
    display: none;
  }

  .page-footer-inner {
    @extend .banner-inner-basic;
    @include containerWidth;
    justify-content: center;
  }

  @media screen and (max-width: 800px) {
    .page-footer {
      display: inline;
    }
  }
</style>

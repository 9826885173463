import httpRequest from '../sharedHelpers/httpRequest'
import { API_NUCLEUS } from '../sharedConfigs/apiConfig'
export default async (leadInfo, siteName) =>
  // {
  //   // throw { response: { status: 500 } }
  //   console.log(leadInfo)
  //   await new Promise((resolve, reject) => {
  //     setTimeout(function () {
  //       resolve({
  //         data: {
  //           status: true,
  //           buyer: {
  //             name: { firstname: 'Longfei', lastname: 'Ma' },
  //             phone: '347-379-3680'
  //           }
  //         }
  //       })
  //     }, 3000)
  //   })
  // }
  httpRequest(
    {
      url: `${API_NUCLEUS.url}${API_NUCLEUS.endpoints.sendPartnerAdLead.endpoint}`,
      method: 'post',
      data: leadInfo
    },
    siteName
  )

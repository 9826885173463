const numOfTrucksPerPage = 36,
  placeHolderImg = 'placeholder.jpg',
  loanPayment = {
    down: 0.2,
    interest: 0.1999,
    term: 48
  },
  truckTypesAndRoutesMap = {
    dealer: { route: 'for-sale', salesType: 'For Sale' },
    lessor: { route: 'for-lease', salesType: 'For Lease' },
    auctioneer: { route: 'at-auction', salesType: 'At Auction' }
  },
  truckLeadTypes = {
    email: 'email',
    call: 'call',
    view: 'view'
  },
  textForWhenThingsGoWrong = {
    requestError: 'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER.',
    individualUnitNotFound: 'THE UNIT HAS BEEN SOLD OR REMOVED.',
    unitsNotFound: 'NO TRUCKS FOUND, PLEASE BROADEN YOUR SEARCH.'
  },
  truckDescriptionBySite = {
    sleepertrader: {
      truckDescription: 'Semi Trucks',
      siteNameTitleCase: 'SleeperTrader'
    },
    daycabtrader: {
      truckDescription: 'Day Cab Trucks',
      siteNameTitleCase: 'DayCabTrader'
    },
    accesstrucks: {
      truckDescription: 'Light And Medium Duty Commercial Trucks',
      siteNameTitleCase: 'AccessTrucks'
    }
  }

export {
  numOfTrucksPerPage,
  placeHolderImg,
  loanPayment,
  truckTypesAndRoutesMap,
  truckLeadTypes,
  textForWhenThingsGoWrong,
  truckDescriptionBySite
}

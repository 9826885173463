// event_category, event_label
function gtag() {
  if (process.env.NODE_ENV === 'production') {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(arguments)
  }
  // else console.log(arguments)
}

export default gtag

<template>
  <div>
    <!-- <Alert v-if="$store.getters.getAlerts.length || $store.getters.getShowFactoringDiv" /> -->
    <Alert v-if="$store.getters.getAlerts.length" />
    <!-- <PartnerAdPopup
      v-if="shouldShowPartnerAdPopup"
      @close-partner-ad-popup="shouldShowPartnerAdPopup = false"
    /> -->
    <router-view />
    <Disclaimer />
    <PageFooter />
  </div>
</template>
<script>
  import PageFooter from '@/components/PageFooter'
  import Alert from '@/components/Alert'
  import Disclaimer from '@/components/Disclaimer'
  import gtag from '../../sharedHelpers/gtag'
  import generateSessionId from '../../sharedHelpers/generateSessionId'
  // import PartnerAdPopup from '@/components/PartnerAdPopup'
  export default {
    // data() {
    //   return {
    //     shouldShowPartnerAdPopup: false
    //   }
    // },
    // mounted() {
    //   let alias = this
    //   setTimeout(() => {
    //     let excludePagePath = {
    //       '/trucks-for-sale/:urlTitle/:truckId': true,
    //       '/offers/:partner/:partnerAdType': true
    //     }
    //     if (!excludePagePath[alias.$route.matched[0].path])
    //       alias.shouldShowPartnerAdPopup = true
    //   }, 150000)
    // },

    created() {
      window.localStorage.removeItem('ffProfile')
      window.localStorage.removeItem('fcProfile')
      window.localStorage.removeItem('interestedInFactoring')
      window.localStorage.removeItem('interestedInFuel')
      let sessionId = window.localStorage.getItem('sessionId')

      if (!sessionId) {
        sessionId = generateSessionId()
        window.localStorage.setItem('sessionId', sessionId)
      }
      gtag('js', new Date())
      //NOTE: all gtag related property names use underscore by google convention
      //      ex. home_see_all
      // gtag('set', { user_id: userId, cookie_prefix: 'SleeperTrader' })
      let userInfo = JSON.parse(window.localStorage.getItem('profile')),
        partnerAdProfiles = JSON.parse(
          localStorage.getItem('partnerAdProfiles')
        ),
        partnerAdCheckBoxesStatus = JSON.parse(
          localStorage.getItem('partnerAdCheckBoxesStatus')
        )
      if (partnerAdProfiles)
        this.$store.commit('updatePartnerAdProfiles', partnerAdProfiles)
      if (partnerAdCheckBoxesStatus)
        this.$store.commit(
          'updatePartnerAdCheckBoxesStatus',
          partnerAdCheckBoxesStatus
        )
      this.$store.commit('updateProfile', {
        ...userInfo,
        sessionId
      })
      window.accessPublishingGoogleAdSlots = {}
    },
    components: {
      PageFooter,
      Alert,
      Disclaimer
      //  PartnerAdPopup
    }
  }
</script>

import getDefaultPayment from './getDefaultPayment'
import { stateFilters } from '../sharedConfigs/filterConfig'
// {dealer:[
//   title, defaultPayment,location,details,price
//   ],
//   lessor:[
//   title, defaultPayment,location,details,downPayment

//   ],
// auctioneer:[
//   title, dealername,location,details,startdate
// ]}
//fields must have value: category, year,manufacturer, city, state, zipcode, dealername, dealerphone, dealeremail,dealerid,(startdate,enddate for auction trucks)
export default (truckInfo, truckType, needDefaultPayment = true) => {
  let {
      dealerweburl,
      dealervideourl,
      cap,
      ghost,
      clientUnitId,
      geolocation,
      hasImage,
      meta,
      __v,
      at,
      td,
      ...rest
    } = truckInfo,
    answer = {
      location: `${truckInfo.city}, ${stateFilters[truckInfo.state]} ${
        truckInfo.zipcode
      }`,
      title: truckInfo.model
        ? `${truckInfo.year} ${truckInfo.manufacturer} ${truckInfo.model}`
        : `${truckInfo.year} ${truckInfo.manufacturer}`,
      urlTitle: `${truckInfo.manufacturer}-${
        truckInfo.model ? truckInfo.model + '-' : ''
      }${truckInfo.state}-${truckInfo.year}-${truckInfo.category}`.replace(
        /[^a-zA-Z0-9]/g,
        '-'
      ),
      ...rest
    }
  if (truckType !== 'auctioneer') {
    if (needDefaultPayment)
      answer.defaultPayment = getDefaultPayment(
        truckInfo.defaultOffer,
        truckType,
        {
          dealerId: truckInfo.dealerid,
          listPrice: truckInfo.listprice,
          yearOfTruck: truckInfo.year
        }
      )
    answer.details = [
      truckInfo.mileage && `${truckInfo.mileage.toLocaleString()} miles`,
      truckInfo.enginedescription,
      truckInfo.horsepower && `${truckInfo.horsepower} hp`
    ]
      .filter((ele) => ele)
      .join(' • ')
    if (truckType === 'dealer')
      answer.price = truckInfo.listprice
        ? `$${truckInfo.listprice.toLocaleString()}`
        : 'Call For Price'
    else
      answer.downPayment =
        truckInfo.defaultOffer && truckInfo.defaultOffer.minDownPayment
          ? `$${parseInt(
              truckInfo.defaultOffer.minDownPayment
            ).toLocaleString()} down`
          : 'Down TBD'
  } else
    answer.details = [
      truckInfo.mileage && `${truckInfo.mileage.toLocaleString()} miles`,
      truckInfo.enginedescription,
      truckInfo.horsepower && `${truckInfo.horsepower} hp`,
      truckInfo.stocknumber && `#${truckInfo.stocknumber}`,
      truckInfo.vin && `vin:${truckInfo.vin}`
    ]
      .filter((ele) => ele)
      .join(' • ')
  return answer
}

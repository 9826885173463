export default ({
  leadType,
  profile,
  message,
  truckId,
  dealerId,
  // siteType = 'st',
  marketingSource,
  shouldSendExtraLeads
}) => ({
  eventType: leadType,
  buyer: {
    id: profile.buyerId,
    firstname: profile.firstName,
    company: profile.company,
    lastname: profile.lastName,
    phone: profile.phoneNumber,
    email: profile.email,
    sessionId: profile.sessionId,
    creditScore: profile.creditScore,
    message,
    downPayment: profile.downPayment,
    state: profile.state
  },
  truck: {
    _id: truckId,
    dealerid: dealerId
  },
  shouldSendExtraLeads,
  // siteType,
  marketingSource
})

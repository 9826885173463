<template>
  <div class="page-header-banner">
    <div v-if="headerTitle" class="page-header-inner">
      <div class="go-back" @click="$router.back()"></div>
      <div class="title" :class="{ weaken: showDropDownNav }">
        {{ headerTitle }}
      </div>
      <div v-if="showDropDownNav" class="drop-down-nav" @click="showDropDown()">
        <PageNavBar />
      </div>
      <div
        class="menu-drop-down"
        @click="showDropDown()"
        :class="{ close: showDropDownNav }"
      ></div>
    </div>
    <div v-else class="page-header-inner">
      <div class="app-logo">
        <router-link class="router-link" to="/trucks-for-sale?page=1">
          <img src="/logo.svg" alt="sleeper trader logo" />
        </router-link>
      </div>
      <div v-if="showNav" class="page-header-nav">
        <button
          class="filter"
          @click="
            $emit('toggle-pop-up', {
              targetKey: 'showFilterPopup',
              value: true
            })
          "
        >
          <span> Filter</span>
        </button>
        <div>
          <PageNavBar />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PageNavBar from '@/components/PageNavBar'

  export default {
    name: 'PageHeader',
    components: { PageNavBar },
    data() {
      return {
        showDropDownNav: false
      }
    },
    emits: ['toggle-pop-up'],
    props: {
      headerTitle: String,
      showNav: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      showDropDown() {
        this.showDropDownNav = !this.showDropDownNav
      }
    }
  }
</script>

<style lang="scss">
  .page-header-banner {
    @extend .banner-basic;

    top: 0;
    background: $dark-1;
  }

  .page-header-inner {
    @extend .banner-inner-basic;
    @include containerWidth;
    justify-content: space-between;
    position: relative;
    .drop-down-nav {
      background: $dark-1;
      z-index: 10;
      position: absolute;
      right: 30px;
    }
  }
  .app-logo {
    img {
      height: 50px;
      margin-top: 2px;
      margin-left: 10px;
    }
  }

  .page-header-nav {
    float: right;
    display: flex;
    .filter {
      cursor: pointer;
      background-color: $dark-1;
      @include backgroundAndPosition($icon-white-tune, center 8px);
      padding: 0 26px;
    }
    span {
      @include bannerSpan(12px);
    }
  }
  .go-back {
    width: 24px;
    @include backgroundAndPosition($icon-white-arrow-back, center 16px);
    padding: 16px 22px;
    cursor: pointer;
  }
  .menu-drop-down:not(.close) {
    @extend .go-back;
    background-image: url($icon-white-menu);
  }
  .menu-drop-down {
    @extend .go-back;
    background-image: url($icon-white-close);
  }

  .title {
    color: $white;
    @include font(18px);
    margin: auto;
    @include textOverflow;
    padding-right: 44px;
  }
  .weaken {
    color: $mid-1;
  }
  @media screen and (max-width: 800px) {
    .page-header-nav {
      .filter {
        @include centerBackground(left 16px);
        margin: 0;
        padding: 0 10px 0 35px;
        span {
          @include font(20px, normal);
          top: 0;
        }
      }
    }
    .page-header-nav > div:last-child,
    .menu-drop-down,
    .drop-down-nav {
      // visibility: hidden;
      display: none;
    }
    .weaken {
      color: $white;
    }
  }
</style>

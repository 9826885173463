<template>
  <div class="nav-bar-container">
    <router-link
      class="router-link"
      to="/trucks-for-sale?page=1"
      active-class="active"
      >Home
    </router-link>
    <!-- <router-link class="router-link" to="/inquired" active-class="active"
      >Inquired
    </router-link> -->
    <router-link class="router-link" to="/faq" active-class="active"
      >FAQ
    </router-link>
    <router-link
      class="router-link"
      to="/profile"
      rel="nofollow"
      active-class="active"
      >Profile
    </router-link>
    <!-- <router-link class="router-link" to="/about-freight-factoring" active-class="active"
      >Factoring
    </router-link> -->
  </div>
</template>
<script>
  export default {
    name: 'PageNavBar'
  }
</script>

<style lang="scss">
  .nav-bar-container {
    width: 100%;
    height: 56px;
    display: flex;
    .router-link {
      cursor: pointer;
      margin-top: 8px;
      padding: 26px 26px 0 26px;
      @include font;
      @include setAllBackground(
        $blue,
        $icon-white-home,
        $icon-white-info,
        $icon-white-account-circle,
        $icon-white-invoice
      );
      @include centerBackground;
    }
    .router-link:not(.active) {
      color: $white;
    }
    .router-link {
      color: $blue;
    }
  }

  @media screen and (max-width: 800px) {
    .nav-bar-container {
      text-align: center;
      .router-link {
        flex: 1;
        margin: 0;
        padding: 28px 0;
        @include setAllBackground(
          $blue,
          $icon-home,
          $icon-info,
          $icon-account-circle,
          $icon-invoice
        );
        @include centerBackground(center 4px);
      }
      .router-link:not(.active) {
        color: $gray-4;
      }
    }
  }
</style>

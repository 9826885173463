<template>
  <div>
    <div class="alert-box">
      <div
        v-for="alert in $store.getters.getAlerts"
        :key="alert.id"
        :class="alert.msgType"
        @click="$store.commit('removeAlert', alert.id)"
        @animationend="$store.commit('removeAlert', alert.id)"
      >
        {{ alert.msg }}
      </div>
      <!-- <div class="factoring-div" v-if="$store.getters.getShowFactoringDiv">
          <h1>FREIGHT</h1>
          <h1>FACTORING</h1>
          <h2>The fastest, smartest and easiest way to get paid.</h2>
          <h2>Are you interested?</h2>
          <div class="btn-div">
            <button class="cancel-btn" @click="$store.commit('showFactoringDiv',false)">
              NO
            </button>
            <button class="save-btn"
            @click="changeRoute"
            >
              YES
            </button>
          </div>
      </div> -->
    </div>
    <!-- <div id="overlay" v-if="$store.getters.getShowFactoringDiv"
      ></div> -->
  </div>
</template>

<script>
  export default {
    name: 'Alert',
    data(){
      return {
      }
    },
    methods:{
      changeRoute(){
        this.$store.commit('showFactoringDiv',false)
        this.$router.push('/about-freight-factoring')
      }
    }
  }
</script>

<style lang="scss">
  .alert-box {
    @include containerWidth(320px, 960px);
    @include fixedFeature;
    width: 95%;
    @include font(14px);
    z-index: 44;
    text-align: center;
  }
  .factoring-div{
    @include borderAndBackgroundColor($white, 1px solid $blue-mid);
    background: linear-gradient($blue-mid, $white);
    padding:15px 35px;
    h1 {
      color:$white;
      font-size:48px;
      text-align: left;
      letter-spacing: 3px;
      text-shadow: 3px 3px $blue-dark;
    }
    h1:nth-child(2){
      color:$blue-dark;
      text-shadow: 3px 3px $white;
    }
    h2 {
      color:$gray-5;
      letter-spacing: 1px;
      text-shadow: 1px 1px $blue-dark;
      text-align: left;
      line-height:72px
    }
  }
  .success,.error,.warning,.info{
    @include alertAnimation;
    opacity: 0.9;
    position: relative;
    visibility: hidden;
    padding: 12px;
    margin: 0 auto 6px auto;
  }
  .success {
    color: $green-dark;
    @include borderAndBackgroundColor($green-light, 1px solid $green-mid);
  }
  .error {
    color: $red-dark;
    @include borderAndBackgroundColor($red-light, 1px solid $red-mid);
  }
  .warning {
    color: $yellow-dark;
    @include borderAndBackgroundColor($yellow-light, 1px solid $yellow-mid);
  }
  .info {
    color: $blue-dark;
    @include borderAndBackgroundColor($blue-light, 1px solid $blue-mid);
  }
</style>

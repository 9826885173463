const adsByPartners = {
    // eCapital: {
    //   'freight factoring': {
    //     filePath: 'factoring',
    //     phoneNumber: '+18885214151',
    //     span: {
    //       emailButtonText: 'Agente de factoraje de correo electrónico!',
    //       callButtonText: 'Agente de factoraje de llamadas'
    //     },
    //     en: {
    //       emailButtonText: 'Email Factoring Agent for a free quote!',
    //       callButtonText: 'Call Factoring Agent'
    //     }
    //   }
    // },
    otrCapital: {
      'freight factoring': {}
    },
    mazoCapital: {
      financing: {
        filePath: 'financing',
        phoneNumber: '+18886981989'
      }
    }
  },
  partnerAdCheckBoxes = {
    'freight factoring': {
      // picUrl:
      //   'https://d2oykiyxwcuabs.cloudfront.net/partner-ads/otrCapital-factoring.jpg',
      // text: 'Please check this box to also have an eCapital factoring agent contact me with information on how I can sell my freight invoices in order to increase cash-flow. Industry leading rates, 100% advance with same day funding, plus much more!'
      text: 'Please also have a SleeperTrader.com Partner Factoring Agent contact me for a free factoring quote.'
    }
    // 'fuel card': { text: 'sample' }
  },
  youtubeVideos = [
    //navistar
    {
      callToActionText:
        '1-Year Factory-Backed Comprehensive Warranty Included on International LT',
      video: {
        src: 'https://www.youtube.com/embed/0sIndas4B_E?t=2s&controls=1',
        landingUrl:
          'https://www.internationalusedtrucks.com/lt-trucks-rh-trucks/'
      }
    },
    //dat
    // {
    //   callToActionText: 'First 30 days free! Sign up here!',
    //   video: {
    //     src: 'https://www.youtube.com/embed/2MzskJVdAE0?t=2s&controls=1',
    //     landingUrl: 'https://www.dat.com/power/0002194100'
    //   }
    // },
    //dat
    {
      callToActionText: 'First 30 days free! Sign up here!',
      video: {
        src: 'https://www.youtube.com/embed/oJqv-fFjxUc?controls=1',
        landingUrl: 'https://www.truckersedge.net/0002194100'
      }
    }
  ],
  imageAds = [
    {
      imagePath: '/SFI_Lease_Inventory_300x250.jpg',
      destinationUrl:
        'https://sfitrucks.com/inventory?utm_source=sleepertrader&utm_medium=display&utm_content=display&utm_campaign=leases'
    },
    {
      imagePath: '/SFI_Lease_LearnMore_300x250.jpg',
      destinationUrl:
        'https://sfitrucks.com/requirements-to-lease-semi-truck?utm_source=sleepertrader&utm_medium=display&utm_content=display&utm_campaign=leases'
    }
  ]
export { adsByPartners, partnerAdCheckBoxes, youtubeVideos, imageAds }

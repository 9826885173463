<template>
  <!-- /21928678287/aggregation_page_pos1 -->
  <div :id="adInfo.divId" class="ad-slot-row"></div>
</template>

<script>
  export default {
    props: {
      adInfo: Object,
      isBottomAd: { type: Boolean, default: false }
    },
    mounted() {
      // console.log('in GAM mount')
      let alias = this,
        mobileMapping1,
        mobileMapping2,
        availableSlotSize
      if (this.isBottomAd) {
        availableSlotSize = [
          [970, 250],
          [480, 320],
          [336, 280],
          [320, 250],
          [300, 600],
          [300, 250]
        ]
        mobileMapping1 = [
          [300, 600],
          [336, 280],
          [320, 250],
          [300, 250]
        ]
        mobileMapping2 = [
          [300, 600],
          [300, 250]
        ]
      } else {
        availableSlotSize = [
          [970, 250],
          [480, 320],
          [336, 280],
          [320, 250],
          [300, 250]
        ]
        mobileMapping1 = [
          [336, 280],
          [320, 250],
          [300, 250]
        ]
        mobileMapping2 = [[300, 250]]
      }
      window.googletag.cmd.push(function () {
        let adSlot = window.googletag
          .defineSlot(
            alias.adInfo.slotId,
            availableSlotSize,
            alias.adInfo.divId
          )
          .setCollapseEmptyDiv(true)
          .addService(window.googletag.pubads())
        adSlot.defineSizeMapping(
          window.googletag
            .sizeMapping()
            .addSize([1024, 768], availableSlotSize)
            .addSize([800, 600], availableSlotSize.slice(1))
            .addSize([400, 300], mobileMapping1)
            .addSize([0, 0], mobileMapping2)
            .build()
        )
        window.accessPublishingGoogleAdSlots[alias.adInfo.divId] = adSlot
        // window.googletag.pubads().enableSingleRequest()
        window.googletag.pubads().enableLazyLoad({
          fetchMarginPercent: 80,
          renderMarginPercent: 50,
          mobileScaling: 1.25
        })
        window.googletag.enableServices()
        window.googletag.display(alias.adInfo.divId)
      })
    },
    // watch: {
    //   $route: function () {
    //     console.log('in GAM watch')
    //     let alias = this
    //     window.googletag.cmd.push(function () {
    //       window.googletag
    //         .pubads()
    //         .refresh([window.accessPublishingGoogleAdSlots[alias.adInfo.divId]])
    //     })
    //   }
    // },
    beforeUnmount() {
      // console.log('in GAM beforeUnmount')
      let alias = this
      window.googletag.cmd.push(function () {
        window.googletag.destroySlots([
          window.accessPublishingGoogleAdSlots[alias.adInfo.divId]
        ])
        window.accessPublishingGoogleAdSlots[alias.adInfo.divId] = undefined
      })
    }
  }
</script>

<style></style>

export default {
  state: {
    cachedPages: {},
    individualTruckHint: null,
    marketTruckId: null,
    yearFilters: {},
    // dealersInfo: {},
  },
  getters: {
    // getDealerInfo: (state) => (key) => state.dealersInfo[key],
    getYearFilters: (state) => (key) => state.yearFilters[key],
    getCachedPage: (state) => (key) => state.cachedPages[key],
    getMarketTruckId: (state) => state.marketTruckId,
    getIndividualCachedTruck: (state) => ({ key, truckId }) => {
      if (state.cachedPages[key]) {
        let targetPage = state.cachedPages[key].trucks
        for (let i = 0; i < targetPage.length; i++) {
          if (targetPage[i]._id === truckId) return targetPage[i]
        }
      }
      return null
    },
    getIndividualTruckHint: (state) => state.individualTruckHint,
  },
  mutations: {
    setMarketTruckId(state, marketTruckId) {
      state.marketTruckId = marketTruckId
    },
    // addDealerInfo(state, newDealer) {
    //   state.dealersInfo = {
    //     ...state.dealersInfo,
    //     [newDealer._id]: newDealer,
    //   }
    // },
    setCachedPage(state, { key, newPage }) {
      state.cachedPages = {
        ...state.cachedPages,
        [key]: newPage,
      }
    },
    setIndividualTruckHint(state, newHint) {
      state.individualTruckHint = newHint
    },
    setYearFilters(state, { key, yearFilters }) {
      state.yearFilters = {
        ...state.yearFilters,
        [key]: yearFilters,
      }
    },
  },
  // actions: {},
}

const // sleepertraderAggregatePage_mediaNet = [
  //     { adSlotId: '461111213', adSize: '320x250' }, //Sleepertrader_Aggregation_1
  //     { adSlotId: '554443321', adSize: '320x250' }, //Sleepertrader_Aggregation_2
  //     { adSlotId: '971261443', adSize: '320x250' } //Sleepertrader_Aggregation_3
  //     // { adSlotId: '712589285', adSize: '320x50' }, //Sleepertrader_Aggregation_4
  //   ],
  // sleepertraderAggregatePage_adsense = [
  //   '3216874483', //Sleepertrader_Aggregation_2
  //   '3644704140', //Sleepertrader_Aggregation_3
  //   '9858758701' //Sleepertrader_Aggregation_4
  // ],
  sleepertrader_adsense_through_GAM = {
    aggregatePage: [
      {
        divId: 'div-gpt-ad-1610469015733-0',
        slotId: '/21928678287/aggregation_page_pos1'
      }, //aggregation_page_pos1
      {
        divId: 'div-gpt-ad-1612886983493-0',
        slotId: '/21928678287/aggregation_page_pos3'
      }, //aggregation_page_pos3
      {
        divId: 'div-gpt-ad-1612981758065-0',
        slotId: '/21928678287/aggregation_page_pos4'
      } //aggregation_page_pos4
    ],
    individualPage: {
      divId: 'div-gpt-ad-1618333879171-0',
      slotId: '/21928678287/aggregation_page-pos2'
      //aggregation_page_pos2
    }
  }
// sleepertraderAggregatePage_adsense_through_GAM_bottom = {
//   divId: 'div-gpt-ad-1618333879171-0',
//   slotId: '/21928678287/aggregation_page-pos2'
//   //aggregation_page_pos2
// },
export {
  // sleepertraderAggregatePage_adsense,
  // sleepertraderAggregatePage_mediaNet,
  sleepertrader_adsense_through_GAM
  // sleepertraderAggregatePage_adsense_through_GAM_bottom
}

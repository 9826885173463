<template>
  <div>
    <PageHeader @toggle-pop-up="toggleCertainPopup" />
    <div class="home-page">
      <h1
        v-show="title"
        class="headline"
        :class="{ 'headline-bar': !!dealerId }"
      >
        {{ title }}
      </h1>
      <HomeTab v-if="!dealerId" />
      <Chips
        v-for="(filterValue, filterName) in activeFilters"
        :filter="filterValue"
        :key="filterName"
        @clear-filter="clearFilter(filterName)"
      />
      <template v-if="trucks">
        <template v-if="trucks.length">
          <div class="trucks-container">
            <template v-for="(truck, index) in trucks" :key="truck._id">
              <!-- <template v-if="partnerAdInfo[index]">
                <PartnerAd
                  :partnerAdConfig="partnerAdInfo[index]"
                  @partner-ad-click="handlePartnerAdClick(index)"
                />
              </template> -->
              <!-- <template v-if="youtubeVideoConfig[index]">
                <YoutubeVideo
                  class="ad-slot-row"
                  :config="youtubeVideoConfig[index]"
                />
              </template> -->
              <!-- <template v-if="imageAdsConfig[index]">
                <ImageAd
                  :config="
                    imageAdsConfig[index][parseInt($route.query.page) % 2]
                  "
                />
              </template> -->
              <!-- <template v-else-if="adsenseAdsInfo[index]"> -->
              <template v-if="adsenseAdsInfo[index]">
                <AdUnit_GAM :adInfo="adsenseAdsInfo[index]" />
              </template>
              <!-- <template v-else-if="mediaNetAdsInfo[index]">
                <AdUnit_mediaNet
                  :key="index"
                  :adSlotInfo="mediaNetAdsInfo[index]"
                  class="ad-slot-row"
                />
              </template> -->
              <TruckCard
                :vw="vw"
                :truckInfo="truck"
                :truckType="truckType || truckTypeForDealerPage"
                @view-individual-truck="
                  $store.commit('setIndividualTruckHint', keyForCurrentPage)
                "
                @toggle-pop-up="toggleCertainPopup"
                @lead-requests="sendLeadRequests"
                :class="{
                  highlight: index === 0 && shouldApplyHighlight
                }"
              />
            </template>
          </div>
          <!-- <PaymentDisclaimer :truckType="truckType" :siteName="siteName" /> -->
          <div v-if="totalPages" class="pagination-div">
            <Pagination :pages="totalPages" />
          </div>
          <AdUnit_GAM :adInfo="adsenseAdsInfo.bottomAd" :isBottomAd="true" />
          <div v-if="totalPages" class="pagination-div">
            <Pagination :pages="totalPages" />
          </div>
          <!-- <div class="ad-slot-row">
            <a
              target="_blank"
              href="https://www.ryder.com/en-us/used-trucks/promotions?utm_source=sleepertrader&utm_medium=digital_ad&utm_id=used_commercial_trucks_fs"
            >
              <img
                width="288"
                height="240"
                :src="ryderAdSrc"
                alt="ryder promotion"
              />
            </a>
          </div> -->
          <Blogs_aggregation_page v-if="truckType === 'dealer'" />
        </template>
        <WhenThingsGoWrong
          v-else
          :text="textForWhenThingsGoWrong.unitsNotFound"
        />
        <Popups
          v-if="shouldDisplayPopup"
          @toggle-pop-up="toggleCertainPopup"
          @lead-requests="sendLeadRequests"
          :popupSwitch="popupSwitch"
        />
        <!-- :interestedPartnerAd="interestedPartnerAd" -->
      </template>
      <WhenThingsGoWrong
        v-else-if="requestError"
        :requestError="true"
        :needButton="!!dealerId"
        :text="textForWhenThingsGoWrong.requestError"
      />
      <Spinner v-else />
      <!-- <div v-if="totalPages" class="pagination-div">
        <Pagination :pages="totalPages" />
      </div> -->
    </div>
  </div>
</template>

<script>
  import { defineAsyncComponent, computed } from 'vue'
  import Spinner from '@/components/Spinner'
  import HomeTab from '@/components/HomeTab'
  import WhenThingsGoWrong from '@/components/WhenThingsGoWrong'
  import Blogs_aggregation_page from '@/components/Blogs_aggregation_page'
  import buildRequestBody from '../../../sharedHelpers/buildRequestBody'
  import transformTruck from '../../../sharedHelpers/transformTruck'
  import { getAggregationPageTitleAndDescription } from '../../../sharedHelpers/getDocumentTitleAndDescription'
  import httpRequest from '../../../sharedHelpers/httpRequest'
  import sendLead from '../../../sharedService/sendLead'
  import { partnerAdLead } from '../../../sharedService/partnerAdLead'
  import { siteName, GA_TRACKING_ID } from '../config/siteSpecificConfig'
  import * as filterConfig from '../config/filterConfig'
  import {
    numOfTrucksPerPage,
    truckTypesAndRoutesMap,
    textForWhenThingsGoWrong
    // truckDescriptionBySite
  } from '../../../sharedConfigs/miscConfig'
  import { throttling } from '../../../sharedHelpers/throttle_debounce_event'
  import { hasImageFilters } from '../../../sharedConfigs/filterConfig'
  import {
    API_TRUCK,
    API_CACHE,
    API_S3
  } from '../../../sharedConfigs/apiConfig'
  // import {
  //   // adsByPartners,
  //   // adsByPartners,
  //   // youtubeVideos
  //   imageAds
  // } from '../../../sharedConfigs/partnerAdsConfig'
  import TruckCard from '@/components/TruckCard'
  import Pagination from '@/components/Pagination'
  import buildUniquePageKey from '../../../sharedHelpers/buildUniquePageKey'
  // import handlePartnerAdClick from '../../../sharedHelpers/handlePartnerAdClick'
  // import Popups from '@/components/Popups'
  // import Chips from '@/components/Chips'
  // import PaymentDisclaimer from '../../../sharedComponents/PaymentDisclaimer'
  // import PartnerAd from '../../../sharedComponents/PartnerAd'
  import {
    // sleepertraderAggregatePage_adsense,
    // sleepertraderAggregatePage_mediaNet,
    sleepertrader_adsense_through_GAM
    // sleepertraderAggregatePage_adsense_through_GAM_bottom,
  } from '../config/adsConfig'
  // import AdUnit_mediaNet from '../../../sharedComponents/AdUnit_mediaNet'
  // import AdUnit_responsive_adsense from '../../../sharedComponents/AdUnit_responsive_adsense'
  import gtag from '../../../sharedHelpers/gtag'
  import { setStatusTag } from '../../../sharedHelpers/prerenderTags'
  import {
    add as addNoIndexMetaTag,
    remove as removeNoIndexMetaTag
  } from '../../../sharedHelpers/noIndexMetaTag'
  import AdUnit_GAM from '../../../sharedComponents/AdUnit_GAM'
  // import ImageAd from '../../../sharedComponents/ImageAd.vue'
  // import YoutubeVideo from '../../../sharedComponents/YoutubeVideo.vue'

  export default {
    name: 'AggregateTrucks',
    data: () => {
      return {
        popupSwitch: {
          showMsgPopup: false,
          showProfilePopup: false,
          showFilterPopup: false,
          showAdEleadPopup: false
        },
        title: null,
        trucks: null,
        siteName,
        vw: 0,
        resizeEvents: [],
        keyForCurrentPage: null,
        truckTypeForDealerPage: null,
        totalPages: null,
        targetTruckForEmailLead: null,
        appliedFilters: null,
        requestError: false,
        textForWhenThingsGoWrong,
        dealerInfo: null,
        adsenseAdsInfo: {
          bottomAd: sleepertrader_adsense_through_GAM.individualPage
        },
        // imageAdsConfig: {},
        // youtubeVideoConfig: {},
        ryderAdSrc: `${API_S3.url}${API_S3.endpoints.partnerAdImage}/ryder.jpg`,
        // googleAdIndex: null,
        // mediaNetAdsInfo: {},
        // partnerAdInfo: {},
        //partner,filePath,adType
        // partnerAdIndexes: null,
        // interestedPartnerAd: {},
        //control if button on popup after being clicked should display loader while request is in process
        shouldShowLoader: false
      }
    },
    provide() {
      return {
        appliedFilters: computed(() => this.appliedFilters),
        pageType: computed(() => this.truckType || this.dealerId),
        shouldShowLoader: computed(() => this.shouldShowLoader)
      }
    },
    computed: {
      shouldApplyHighlight: function () {
        let { mUnitId, page } = this.$route.query
        return mUnitId && page === '1' && this.$store.getters.getMarketTruckId
      },
      shouldDisplayPopup: function () {
        return (
          this.popupSwitch.showProfilePopup ||
          this.popupSwitch.showMsgPopup ||
          this.popupSwitch.showFilterPopup ||
          this.popupSwitch.showAdEleadPopup
        )
      },
      activeFilters: function () {
        let activeFilters = {}
        for (let key in this.appliedFilters) {
          if (this.appliedFilters[key]) {
            if (key === 'hasImage') {
              activeFilters[key] = hasImageFilters[this.appliedFilters[key]]
            } else activeFilters[key] = this.appliedFilters[key]
          }
        }
        return activeFilters
      }
    },
    props: {
      truckType: String,
      dealerId: String
    },
    components: {
      HomeTab,
      TruckCard,
      Pagination,
      Popups: defineAsyncComponent(() => import('@/components/Popups.vue')),
      Chips: defineAsyncComponent(() => import('@/components/Chips')),
      Spinner,
      WhenThingsGoWrong,
      // PaymentDisclaimer,
      // AdUnit_responsive_adsense,
      AdUnit_GAM,
      // PartnerAd,
      Blogs_aggregation_page
      // ImageAd
      // YoutubeVideo
    },
    async created() {
      this.vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      )
      this.setResizeEvents()
      await this.getYearFilters()
      let getTrucksRes = this.getTrucks()
      this.setUpAdsData()
      await getTrucksRes
      this.seoSetting()
      //get appliedFilters from query
      this.getAppliedFilters()
      if (this.trucks && this.trucks.length)
        gtag('config', GA_TRACKING_ID, {
          page_path: this.$route.fullPath
        })
    },
    beforeUnmount() {
      removeNoIndexMetaTag()
      for (let i = 0; i < this.resizeEvents.length; i++)
        window.removeEventListener('resize', this.resizeEvents[i])
    },
    watch: {
      $route: {
        handler: async function () {
          this.popupSwitch.showProfilePopup =
            this.popupSwitch.showMsgPopup =
            this.popupSwitch.showFilterPopup =
            this.popupSwitch.showAdEleadPopup =
              false
          this.trucks = this.title = null
          await this.getYearFilters()
          await this.getTrucks()
          // this.setUpPartnerAdsData()
          this.seoSetting()
          this.getAppliedFilters()
          if (this.trucks && this.trucks.length)
            gtag('config', GA_TRACKING_ID, {
              page_path: this.$route.fullPath
            })
        },
        flush: 'post'
      }
    },
    methods: {
      // handlePartnerAdClick(adIndex) {
      //   this.interestedPartnerAd = this.partnerAdInfo[adIndex]
      //   // this.popupSwitch.showAdEleadPopup = true
      //   handlePartnerAdClick(this, {
      //     ...this.interestedPartnerAd,
      //     adFormat: 'display_on_aggregate'
      //   })
      // },
      // setUpPartnerAdsData() {
      //   this.partnerAdInfo = {}
      //   let partnerAdIndex = 0,
      //     adOrder = [{ partner: 'mazoCapital', adType: 'financing' }]
      //   for (let i = 0; i < adOrder.length; i++) {
      //     let { partner, adType } = adOrder[i],
      //       // { page } = this.$route.query,
      //       {
      //         filePath,
      //         phoneNumber
      //         //span, en
      //       } = adsByPartners[partner][adType]
      //     this.partnerAdInfo[this.partnerAdIndexes[partnerAdIndex++]] = {
      //       filePath,
      //       phoneNumber,
      //       // ...(page % 2 ? { ...en, lang: 'en' } : { lang: 'span', ...span }),
      //       adType,
      //       partner
      //     }
      //   }
      // },
      setUpAdsData() {
        let adsenseAdIndexes
        // ,
        // imageAdIndexes
        // ,
        // youtubeVideoIndexes,
        // videoWidth,
        // videoHeight
        // ,mediaNetAdIndexes
        //vw 700 2 units/row, 1000 3 units/row  else 4 units/row
        if (this.vw <= 700) {
          //number stands for number of units and "-" stands for ad
          //2-6-6-6-6-8
          // available slots index [2,8,14,20,28]
          // this.partnerAdIndexes = [20]
          // youtubeVideoIndexes = [2, 14]
          // imageAdIndexes = [2]
          adsenseAdIndexes = [2, 8, 14]
          // adsenseAdIndexes = [8, 20, 28]
          // mediaNetAdIndexes = [20, 28]
        } else {
          // videoWidth = 480
          // videoHeight = 270
          if (this.vw <= 1000) {
            //3-6-6-6-6-9
            // available slots index [3,9,15,21,27]
            // this.partnerAdIndexes = [21]
            // imageAdIndexes = [3]
            adsenseAdIndexes = [3, 9, 15]
            // youtubeVideoIndexes = [3, 15]
            // adsenseAdIndexes = [9, 21, 27]
            // mediaNetAdIndexes = [21, 27]
          } else {
            //4-8-4-8-4-8
            // available slots index [4,12,16,24,28]
            // this.partnerAdIndexes = [24]
            // imageAdIndexes = [4]
            adsenseAdIndexes = [4, 12, 16]
            // youtubeVideoIndexes = [4, 16]
            // adsenseAdIndexes = [12, 24, 28]
            // mediaNetAdIndexes = [24, 28]
          }
        }
        // this.setUpPartnerAdsData()
        for (let i = 0; i < adsenseAdIndexes.length; i++) {
          this.adsenseAdsInfo[adsenseAdIndexes[i]] =
            sleepertrader_adsense_through_GAM.aggregatePage[i]
        }
        // if (Math.random() < 0.5) {
        //   imageAds.push(imageAds.shift())
        // }
        // this.imageAdsConfig[imageAdIndexes[0]] = imageAds
        // for (let i = 0; i < youtubeVideoIndexes.length; i++) {
        //   this.youtubeVideoConfig[youtubeVideoIndexes[i]] = {
        //     video: youtubeVideos[i].video,
        //     callToActionText: youtubeVideos[i].callToActionText,
        //     width: videoWidth,
        //     height: videoHeight
        //   }
        // }
        // for (let i = 0; i < mediaNetAdIndexes.length; i++) {
        //   this.mediaNetAdsInfo[mediaNetAdIndexes[i]] =
        //     sleepertraderAggregatePage_mediaNet[i]
        // }
      },
      setResizeEvents() {
        let thisRef = this
        this.resizeEvents.push(
          throttling(function () {
            thisRef.vw = Math.max(
              document.documentElement.clientWidth || 0,
              window.innerWidth || 0
            )
          }, 250)
        )
        for (let i = 0; i < this.resizeEvents.length; i++)
          window.addEventListener('resize', this.resizeEvents[i])
      },
      seoSetting() {
        if (!this.trucks || this.trucks.length === 0) {
          addNoIndexMetaTag()
          setStatusTag(404)
        } else {
          removeNoIndexMetaTag()
          setStatusTag(200)
        }
        this.setCanonicalURL()
        this.setDocumentTitleAndDescription()
      },
      setCanonicalURL() {
        let {
            query: { year, manufacturer, model, state, category },
            path
          } = this.$route,
          url = `https://${siteName}.com${path}`,
          query = ''
        if (manufacturer) query += `manufacturer=${encodeURI(manufacturer)}&`
        if (model) query += `model=${encodeURI(model)}&`
        if (state) query += `state=${state}&`
        if (year) query += `year=${year}&`
        if (category) query += `category=${encodeURI(category)}&`
        if (query) url += '?' + query.slice(0, -1)
        document
          .querySelector('link[rel="canonical"]')
          .setAttribute('href', url)
      },
      setDocumentTitleAndDescription() {
        let documentTitleAndDescription = this.truckType
          ? //regular page
            getAggregationPageTitleAndDescription(
              siteName,
              this.truckType,
              this.$route.query
            )
          : //dealer page
          this.requestError || (this.trucks && this.trucks.length === 0)
          ? //fall back if 1.request failed; 2.no units for this dealer or dealer is not in DB
            {
              title: `Units for ${this.dealerId}`,
              description: `Units for ${this.dealerId}`
            }
          : getAggregationPageTitleAndDescription(
              siteName,
              this.truckTypeForDealerPage,
              this.$route.query,
              this.dealerInfo.name
            )
        this.title = documentTitleAndDescription.title.split('|')[0]
        document.title = documentTitleAndDescription.title
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', documentTitleAndDescription.description)
      },
      async getYearFilters() {
        if (
          !this.$store.getters.getYearFilters(this.truckType || this.dealerId)
        ) {
          let yearFiltersRes
          try {
            let filter = {
              [API_TRUCK.endpoints.getDistinctFields.requestBodyKeyName]: 'year'
            }
            if (this.truckType) filter[this.truckType] = true
            else filter.dealerids = [this.dealerId]
            yearFiltersRes = (
              await httpRequest(
                {
                  url: `${API_TRUCK.url}${API_TRUCK.endpoints.getDistinctFields.endpoint}`,
                  method: 'post',
                  data: filter
                },
                siteName
              )
            ).data[API_TRUCK.endpoints.getDistinctFields.responseKeyName]
          } catch (err) {
            //provide a list range of 30 years
            yearFiltersRes = []
            let currentYear = new Date().getFullYear()
            for (let i = currentYear + 1; currentYear - i + 1 < 30; i--)
              yearFiltersRes.push(i)
          }
          let yearFilters = {}
          for (let i = 0; i < yearFiltersRes.length; i++)
            yearFilters[`${yearFiltersRes[i]} `] = yearFiltersRes[i]
          this.$store.commit('setYearFilters', {
            key: this.truckType || this.dealerId,
            yearFilters
          })
        }
      },
      getAppliedFilters() {
        this.appliedFilters = {
          category: '',
          manufacturer: '',
          price: '',
          year: '',
          state: '',
          horsepower: '',
          hasImage: '',
          model: ''
        }
        let query = this.$route.query
        for (let filerTitle in this.appliedFilters) {
          if (query[filerTitle])
            this.appliedFilters[filerTitle] = query[filerTitle]
        }
      },
      async getTrucks() {
        this.keyForCurrentPage = buildUniquePageKey(this, filterConfig)
        let cachedPage = this.$store.getters.getCachedPage(
          this.keyForCurrentPage
        )
        if (cachedPage) {
          // console.log('get from cache')
          this.trucks = cachedPage.trucks
          this.totalPages = cachedPage.totalPages
          this.truckTypeForDealerPage = cachedPage.truckTypeForDealerPage
          this.dealerInfo = cachedPage.dealerInfo
        } else {
          // console.log('get from server')
          await this.getAndTransformTrucks()
          if (!this.requestError)
            this.$store.commit('setCachedPage', {
              key: this.keyForCurrentPage,
              newPage: {
                trucks: this.trucks,
                totalPages: this.totalPages,
                truckTypeForDealerPage: this.truckTypeForDealerPage,
                dealerInfo: this.dealerInfo
              }
            })
        }
      },
      async getMarketTruck(mUnitId) {
        try {
          let response = (
            await httpRequest(
              {
                method: 'post',
                url: `${API_TRUCK.url}${API_TRUCK.endpoints.getTruck.endpoint}`,
                data: buildRequestBody({
                  truckType: this.truckType,
                  dealerId: this.dealerId,
                  truckId: mUnitId
                })
              },
              siteName
            )
          ).data
          if (response.status)
            return response[API_TRUCK.endpoints.getTruck.responseKeyName]
        } catch (error) {
          // console.log(error.stack)
        }
      },
      async getAndTransformTrucks() {
        let requestBody = buildRequestBody({
            truckType: this.truckType,
            dealerId: this.dealerId,
            range: numOfTrucksPerPage,
            query: this.$route.query
          }),
          getTrucksUrl,
          headers,
          { mUnitId, page } = this.$route.query,
          highlightTruck
        if (mUnitId && page === '1')
          highlightTruck = this.getMarketTruck(mUnitId)
        if (requestBody.pool) {
          getTrucksUrl = API_CACHE
          headers = { 'x-request-id': window.localStorage.getItem('sessionId') }
        } else getTrucksUrl = API_TRUCK
        try {
          let response = (
            await httpRequest(
              {
                method: 'post',
                headers,
                url: `${getTrucksUrl.url}${getTrucksUrl.endpoints.getTrucks.endpoint}`,
                data: requestBody
              },
              siteName
            )
          ).data
          if (response.status) {
            this.totalPages = Math.ceil(
              response.totalAvailable / numOfTrucksPerPage
            )
            this.dealerInfo = response.client
            let trucksRawData =
              response[getTrucksUrl.endpoints.getTrucks.responseKeyName] || []
            if (this.dealerId && trucksRawData.length) {
              let firstTruck = trucksRawData[0],
                truckTypes = Object.keys(truckTypesAndRoutesMap)
              for (let i = 0; i < truckTypes.length; i++) {
                if (firstTruck[truckTypes[i]]) {
                  this.truckTypeForDealerPage = truckTypes[i]
                  break
                }
              }
            }
            // throw new Error()
            let i = 0,
              truckType = this.truckType || this.truckTypeForDealerPage
            highlightTruck = await highlightTruck
            if (highlightTruck) {
              this.$store.commit('setMarketTruckId', highlightTruck._id)
              this.trucks = [transformTruck(highlightTruck, truckType)]
              i = 1
            } else this.trucks = []
            for (i; i < trucksRawData.length; i++)
              this.trucks.push(transformTruck(trucksRawData[i], truckType))
          }
        } catch (error) {
          this.requestError = true
        }
      },
      toggleCertainPopup({
        targetKey,
        value,
        targetTruck
        // interestedPartnerAd
      }) {
        if (value) {
          for (let key in this.popupSwitch) {
            if (key !== targetKey) this.popupSwitch[key] = false
          }
        }
        this.popupSwitch[targetKey] = value
        if (targetTruck) this.targetTruckForEmailLead = targetTruck
        // if (interestedPartnerAd) this.interestedPartnerAd = interestedPartnerAd
      },

      async sendTruckLead(leadDetail) {
        let targetTruck
        //leads that provide targetTruck(call, view)
        if (leadDetail.targetTruck)
          targetTruck = {
            truckId: leadDetail.targetTruck._id,
            dealerId: leadDetail.targetTruck.dealerid
          }
        else
          targetTruck = {
            truckId: this.targetTruckForEmailLead._id,
            dealerId: this.targetTruckForEmailLead.dealerid
          }
        await sendLead(this, {
          leadType: leadDetail.leadType,
          targetTruck,
          message: leadDetail.message,
          siteName,
          shouldSendExtraLeads: leadDetail.shouldSendExtraLeads
        })
      },
      async sendPartnerAdLead(leadDetail) {
        let { leadTypes, fromCheckBox } = leadDetail,
          {
            firstName,
            lastName,
            email,
            phoneNumber,
            message,
            company,
            mc_dot_number
          } = fromCheckBox ? this.$store.getters.getProfile : leadDetail,
          partnerAdRespondentProfile = {
            firstName,
            lastName,
            email,
            phoneNumber,
            company,
            leadTypes
          }
        if (message) partnerAdRespondentProfile.message = message
        if (mc_dot_number)
          partnerAdRespondentProfile.mc_dot_number = mc_dot_number
        // if (!fromCheckBox)
        //   partnerAdRespondentProfile.partner = this.interestedPartnerAd.partner
        await partnerAdLead(this, partnerAdRespondentProfile, siteName)
      },
      //all lead requests will go through this function,
      // if more than one lead in a single event, each should be in the format of { functionName, lead }
      async sendLeadRequests(leadsDetail) {
        this.shouldShowLoader = true
        for (let { functionName, lead } of leadsDetail) {
          await this[functionName](lead)
        }
        this.shouldShowLoader = false
      },
      clearFilter(filterName) {
        this.appliedFilters = {
          ...this.appliedFilters,
          [filterName]: ''
        }
        let newPath = {
          path: this.$route.path,
          query: { ...this.$route.query, page: 1 }
        }
        delete newPath.query[filterName]
        this.$router.push(newPath)
      }
    }
  }
</script>

<style lang="scss">
  .headline {
    padding: 10px 5px;
    text-align: center;
    font-size: 16px;
    color: $blue;
  }
  .headline-bar {
    margin: 0 5px;
    border-radius: 0.5rem;
    background-color: $blue;
    color: $white;
  }
  .home-page {
    @include containerWidth;
    @include containerPosition;
  }
  .trucks-container {
    // position: relative;
    padding: 6px;
    @include displayGrid(repeat(4, 1fr));
  }
  .pagination-div {
    // position: relative;
    cursor: pointer;
    width: 280px;
    height: 50px;
    margin: 4px auto 12px auto;
  }
  .ad-style {
    width: 95%;
    display: flex;
    margin: 6px;
    justify-content: center;
    align-items: center;
  }
  .ad-slot-row {
    display: flex;
    margin: 25px 0;
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: 1001px) {
    .ad-slot-row {
      grid-column: 1/5;
    }
  }

  @media screen and (max-width: 1000px) {
    .trucks-container {
      grid-template-columns: repeat(3, 1fr);
    }
    .ad-slot-row {
      grid-column: 1/4;
    }
  }
  @media screen and (max-width: 800px) {
    .home-page {
      .pop-up {
        margin: 0;
      }
    }
  }
  @media screen and (max-width: 700px) {
    .trucks-container {
      grid-template-columns: repeat(2, 1fr);
    }
    .ad-style {
      margin: 25px auto;
      grid-column: 1/3;
    }
    .ad-slot-row {
      grid-column: 1/3;
    }
  }
</style>

export default ({ range = 24, query = {}, truckType, dealerId, truckId }) => {
  let body
  //individual truck
  if (truckId) {
    if (truckType) body = { truckId, [truckType]: true }
    else body = { truckId }
  }
  //list of trucks
  else {
    let {
      category,
      manufacturer,
      price,
      state,
      year,
      hasImage,
      horsepower,
      model,
      page
    } = query
    page = parseInt(page) || 1
    //list of trucks by truckType
    if (truckType) {
      //with query
      if (
        category ||
        manufacturer ||
        price ||
        state ||
        year ||
        hasImage ||
        horsepower ||
        model
      ) {
        const priceRange = price ? price.split('-') : undefined
        let horsepowerRange
        if (horsepower) {
          if (horsepower.includes('-')) {
            horsepowerRange = horsepower ? horsepower.split('-') : undefined
          } else horsepowerRange = [horsepower, horsepower]
        }
        body = {
          categories: category,
          manufacturers: manufacturer,
          page,
          models: model,
          pricemax: priceRange && parseInt(priceRange[1]),
          pricemin: priceRange && parseInt(priceRange[0]),
          yearmin: year && parseInt(year),
          yearmax: year && parseInt(year),
          hpmax: horsepowerRange && parseInt(horsepowerRange[1]),
          hpmin: horsepowerRange && parseInt(horsepowerRange[0]),
          range,
          states: state,
          hasImage: hasImage === 'true',
          [truckType]: true
        }
      }
      //without query
      else
        body = {
          [truckType]: true,
          page,
          range,
          pool: truckType === 'dealer',
        }
    }
    //list of trucks by dealer with query
    else if (
      category ||
      manufacturer ||
      price ||
      state ||
      year ||
      hasImage ||
      horsepower ||
      model
    ) {
      const priceRange = price ? price.split('-') : undefined
      let horsepowerRange
      if (horsepower) {
        if (horsepower.includes('-')) {
          horsepowerRange = horsepower ? horsepower.split('-') : undefined
        } else horsepowerRange = [horsepower, horsepower]
      }
      body = {
        categories: category,
        manufacturers: manufacturer,
        page,
        models: model,
        pricemax: priceRange && parseInt(priceRange[1]),
        pricemin: priceRange && parseInt(priceRange[0]),
        yearmin: year && parseInt(year),
        yearmax: year && parseInt(year),
        hpmax: horsepowerRange && parseInt(horsepowerRange[1]),
        hpmin: horsepowerRange && parseInt(horsepowerRange[0]),
        range,
        states: state,
        dealerids: dealerId,
        hasImage: hasImage === 'true'
      }
    }
    //list of trucks by dealer without query
    else
      body = {
        dealerids: dealerId,
        page,
        range
      }
  }
  return body
}

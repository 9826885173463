const categoryFilters = {
    'Agricultural Trailers': 'Agricultural Trailers',
    'Agricultural Trucks': 'Agricultural Trucks',
    'Box Trucks': 'Box Trucks',
    'Bucket Trucks': 'Bucket Trucks',
    'Cab & Chassis': 'Cab & Chassis',
    'Car Carrier Trucks': 'Car Carrier Trucks',
    'Cargo Vans': 'Cargo Vans',
    'Conventional W/o Sleeper': 'Conventional W/o Sleeper',
    'Crane Trucks': 'Crane Trucks',
    'Drop Deck Trailers': 'Drop Deck Trailers',
    'Dump Trailers': 'Dump Trailers',
    'Dump Trucks': 'Dump Trucks',
    'Flatbed Trucks': 'Flatbed Trucks',
    'Garbage Trucks': 'Garbage Trucks',
    'Landscape Trucks': 'Landscape Trucks',
    'Lowboy Trailers': 'Lowboy Trailers',
    'Mixer Trucks': 'Mixer Trucks',
    Other: 'Other',
    'Pickup Trucks': 'Pickup Trucks',
    'Plow Trucks': 'Plow Trucks',
    'Reefer Trucks': 'Reefer Trucks',
    'Roll-off Trucks': 'Roll-off Trucks',
    'Service Trucks': 'Service Trucks',
    'Spotter Trucks': 'Spotter Trucks',
    'Tank Trailers': 'Tank Trailers',
    'Tank Trucks': 'Tank Trucks',
    'Tow Trucks': 'Tow Trucks',
    'Winch Trucks': 'Winch Trucks'
  },
  manufacturerFilters = {
    Benson: 'Benson',
    Chevrolet: 'Chevrolet',
    Dodge: 'Dodge',
    Dorsey: 'Dorsey',
    'Eager Beaver': 'Eager Beaver',
    East: 'East',
    Etnyre: 'Etnyre',
    Fontaine: 'Fontaine',
    Ford: 'Ford',
    Freightliner: 'Freightliner',
    Fruehauf: 'Fruehauf',
    GMC: 'GMC',
    'Great Dane': 'Great Dane',
    Hino: 'Hino',
    Hyundai: 'Hyundai',
    International: 'International',
    Isuzu: 'Isuzu',
    Kenworth: 'Kenworth',
    Mac: 'Mac',
    Mack: 'Mack',
    Manac: 'Manac',
    Mitsubishi: 'Mitsubishi',
    Other: 'Other',
    Ottawa: 'Ottawa',
    Peterbilt: 'Peterbilt',
    Polar: 'Polar',
    Ravens: 'Ravens',
    Reitnouer: 'Reitnouer',
    Rogers: 'Rogers',
    Sterling: 'Sterling',
    Stoughton: 'Stoughton',
    Strick: 'Strick',
    Talbert: 'Talbert',
    Timpte: 'Timpte',
    'Trail King': 'Trail King',
    Trailmobile: 'Trailmobile',
    Transcraft: 'Transcraft',
    Travis: 'Travis',
    Utility: 'Utility',
    Vanguard: 'Vanguard',
    Volvo: 'Volvo',
    Wabash: 'Wabash',
    'Western Star': 'Western Star',
    Wilkens: 'Wilkens',
    Wilson: 'Wilson',
    'Witzco Challenger': 'Witzco Challenger',
    'XL Specialized': 'XL Specialized'
  },
  // {
  //   Peterbilt: 'Peterbilt',
  //   Kenworth: 'Kenworth',
  //   International: 'International',
  //   Volvo: 'Volvo',
  //   Freightliner: 'Freightliner',
  //   'Western Star': 'Western Star',
  //   Mack: 'Mack',
  //   Utility: 'Utility',
  //   'Great Dane': 'Great Dane',
  //   Wabash: 'Wabash',
  //   Other: 'Other'
  // },
  keyOrder = {
    category: '0',
    hasImage: '1',
    horsepower: '2',
    mUnitId: '3',
    manufacturer: '4',
    model: '5',
    page: '6',
    price: '7',
    state: '8',
    year: '9'
  }
export { categoryFilters, manufacturerFilters, keyOrder }

const categoryFilters = {
    'Conventional W/ Sleeper': 'Conventional W/ Sleeper',
    'Glider Kit Trucks': 'Glider Kit Trucks',
    'Dry Van Trailers': 'Dry Van Trailers',
    'Flatbed Trailers': 'Flatbed Trailers',
    'Reefer Trailers': 'Reefer Trailers'
  },
  manufacturerFilters = {
    Peterbilt: 'Peterbilt',
    Kenworth: 'Kenworth',
    International: 'International',
    Volvo: 'Volvo',
    Freightliner: 'Freightliner',
    'Western Star': 'Western Star',
    Mack: 'Mack',
    Utility: 'Utility',
    'Great Dane': 'Great Dane',
    Wabash: 'Wabash',
    Other: 'Other'
  },
  keyOrder = {
    category: '0',
    hasImage: '1',
    horsepower: '2',
    mUnitId: '3',
    manufacturer: '4',
    model: '5',
    page: '6',
    price: '7',
    state: '8',
    year: '9'
  }
export { categoryFilters, manufacturerFilters, keyOrder }

import deepCloneObj from '../../../../sharedHelpers/deepCloneObj'
export default {
  state: {
    profile: { creditScore: '', state: '' },
    emailedUnits: {},
    partnerAdProfiles: {},
    partnerAdCheckBoxesStatus: {}
  },
  getters: {
    getEmailedUnits: (state) => state.emailedUnits,
    profileValid: (state) => !!state.profile.firstName,
    getProfile: (state) => state.profile,
    getSessionId: (state) => state.profile.sessionId,
    getPartnerAdProfiles: (state) => state.partnerAdProfiles,
    getPartnerAdProfile: (state, getters) => (
      adType,
      shouldUseProfile = true
    ) => {
      let { partnerAdProfiles } = state
      if (partnerAdProfiles[adType])
        return deepCloneObj(partnerAdProfiles[adType])
      if (shouldUseProfile && getters.profileValid) {
        let { firstName, lastName, email, phoneNumber, company } = state.profile
        return { firstName, lastName, email, phoneNumber, company }
      }
    },
    getPartnerAdCheckBoxesStatus: (state) => state.partnerAdCheckBoxesStatus,
    getPartnerAdCheckBoxesStatusByAdType: (state) => (adType) =>
      state.partnerAdCheckBoxesStatus[adType]
  },
  mutations: {
    updateProfile(state, newProfile) {
      state.profile = {
        ...state.profile,
        ...newProfile
      }
      window.localStorage.setItem('profile', JSON.stringify(state.profile))
    },
    deleteProfile(state) {
      state.profile = { creditScore: '', state: '' }
      window.localStorage.removeItem('profile')
    },
    addEmailedUnit(state, newUnit) {
      state.emailedUnits = {
        ...state.emailedUnits,
        [newUnit.id]: newUnit.emailDate
      }
    },
    updatePartnerAdProfile(state, { adType, profile }) {
      state.partnerAdProfiles = {
        ...state.partnerAdProfiles,
        [adType]: profile
      }
      window.localStorage.setItem(
        'partnerAdProfiles',
        JSON.stringify(state.partnerAdProfiles)
      )
    },
    //called when sync from localstorage
    updatePartnerAdProfiles(state, newPartnerAdProfiles) {
      state.partnerAdProfiles = newPartnerAdProfiles
    },
    //called when sync from localstorage
    updatePartnerAdCheckBoxesStatus(state, newPartnerAdCheckBoxesStatus) {
      state.partnerAdCheckBoxesStatus = newPartnerAdCheckBoxesStatus
    },
    updatePartnerAdCheckBoxesStatusByAdType(state, { adType, status }) {
      state.partnerAdCheckBoxesStatus = {
        ...state.partnerAdCheckBoxesStatus,
        [adType]: status
      }
      window.localStorage.setItem(
        'partnerAdCheckBoxesStatus',
        JSON.stringify(state.partnerAdCheckBoxesStatus)
      )
    }
  }
  // actions: {},
}

let deepCloneObj = (obj) => {
  if (typeof obj === 'object') {
    let answer
    if (Array.isArray(obj)) {
      answer = []
      for (let i = 0, loopTimes = obj.length; i < loopTimes; i++) {
        answer.push(deepCloneObj(obj[i]))
      }
    } else {
      answer = {}
      for (let key in obj) answer[key] = deepCloneObj(obj[key])
    }
    return answer
  } else return obj
}
export default deepCloneObj
